
<div
    v-if="autoPlay"
    class="carousel-scroll"
    @mouseover="onMouseEnter"
    @mouseleave="onMouseLeave">
  <swiper
      v-if="isMounted"
      :options="swiperOption"
      ref="swiperAutoplay">
    <swiper-slide
        v-for="article in formattedArticles"
        :key="article.order">
      <article-base
          :article="article"
          :index="article.order">
        <template v-slot:[article.order]>
          <slot :name="article.order"></slot>
        </template>
        <template v-slot:[`textContainerHead-${article.order}`]>
          <slot :name="`textContainerHead-${article.order}`"></slot>
        </template>
      </article-base>
    </swiper-slide>
  </swiper>
  <div
      class="swiper-scrollbar"
      :class="`${hideBottomPart ? 'hidden sm:block' : ''}`"
      :ref="`scrollbar-${uniqueId}`"/>
</div>
<div
    v-else
    class="carousel-scroll">
  <swiper
      v-if="isMounted"
      :options="swiperOption">
    <swiper-slide
        v-for="article in formattedArticles"
        :key="article.order">
      <article-base
          :article="article"
          :index="article.order">
        <template v-slot:[article.order]>
          <slot :name="article.order"></slot>
        </template>
        <template v-slot:[`textContainerHead-${article.order}`]>
          <slot :name="`textContainerHead-${article.order}`"></slot>
        </template>
        <template v-slot:[`placeholderTitleTeaser-${article.order}`]>
          <slot :name="`placeholderTitleTeaser-${article.order}`"/>
        </template>
      </article-base>
    </swiper-slide>
  </swiper>
  <div
      class="swiper-scrollbar"
      :class="`${hideBottomPart ? 'hidden sm:block' : ''}`"
      :ref="`scrollbar-${uniqueId}`"/>
  <div class="swiper-button-wrapper" :class="`${hideBottomPart ? 'hidden' : ''}`">
    <div class="swiper-button-prev" :ref="`prev-button-${uniqueId}`">
      <svg
        v-if="!lightDetails"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none">
        <path
          d="M0.402864 8.60121C2.94774 10.0645 5.93796 13.4365 6.95591 16.1722L9.30992 16.1722C8.19654 13.341 5.65166 10.4781 3.74301 9.11018L23.6566 9.11018L23.6566 7.1061L3.74301 7.1061C5.68348 5.73823 8.22835 2.93887 9.27811 0.107694L6.95591 0.107694C5.96977 2.84343 2.97955 6.15177 0.402864 7.61507L0.402864 8.60121Z"
          fill="#232528"/>
      </svg>
      <svg
        v-if="lightDetails"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none">
        <path
          d="M0.402864 8.60121C2.94774 10.0645 5.93796 13.4365 6.95591 16.1722L9.30992 16.1722C8.19654 13.341 5.65166 10.4781 3.74301 9.11018L23.6566 9.11018L23.6566 7.1061L3.74301 7.1061C5.68348 5.73823 8.22835 2.93887 9.27811 0.107694L6.95591 0.107694C5.96977 2.84343 2.97955 6.15177 0.402864 7.61507L0.402864 8.60121Z"
          fill="#FFFFFF"/>
      </svg>
    </div>
    <div class="swiper-button-next" :ref="`next-button-${uniqueId}`">
      <svg
        v-if="!lightDetails"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none">
        <path
          d="M23.5971 8.39879C21.0523 6.93549 18.062 3.56353 17.0441 0.827793L14.6901 0.827793C15.8035 3.65896 18.3483 6.52195 20.257 7.88982L0.343357 7.88982L0.343357 9.8939L20.257 9.8939C18.3165 11.2618 15.7717 14.0611 14.7219 16.8923L17.0441 16.8923C18.0302 14.1566 21.0205 10.8482 23.5971 9.38493L23.5971 8.39879Z"
          fill="#232528"/>
      </svg>
      <svg
        v-if="lightDetails"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="17"
        viewBox="0 0 24 17"
        fill="none">
        <path
          d="M23.5971 8.39879C21.0523 6.93549 18.062 3.56353 17.0441 0.827793L14.6901 0.827793C15.8035 3.65896 18.3483 6.52195 20.257 7.88982L0.343357 7.88982L0.343357 9.8939L20.257 9.8939C18.3165 11.2618 15.7717 14.0611 14.7219 16.8923L17.0441 16.8923C18.0302 14.1566 21.0205 10.8482 23.5971 9.38493L23.5971 8.39879Z"
          fill="#FFFFFF"/>
      </svg>
    </div>
  </div>
</div>
